import Addresses from './Addresses'
import NewAddress from './NewAddress'
import Map  from './Map'
import EditAddress from './EditAddress'

export {
  Addresses,
  NewAddress,
  Map,
  EditAddress,
}

export default {
  Addresses,
  NewAddress,
  Map,
  EditAddress,
}
