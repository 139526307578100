import { useState, useMemo, useLayoutEffect, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'
import throttle from 'lodash.throttle'
import { geocodeByPlaceId } from 'react-places-autocomplete'

import baseConfig from '@baseConfig'
import CustomInput from '@components/CustomInput'
import useDebounce from '@hooks/useDebounce'
import useGlossary from '@hooks/useGlossary'
import PinIcon from '@icons/PinIcon'
import { addressFormatter } from '@helpers/addressFormatter'
import EditAddress from './EditAddress'
import Map from './Map'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  minHeight: '100%',
}))

const SubContainer = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}))

const OptionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}))

const OptionAddressContainer = styled(props => <Button {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  color: theme.palette.gray[700],
  textAlign: 'left',
  textTransform: 'capitalize',
  padding: `${theme.spacing(0.5)} 0`,
}))

const OptionAddress = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  width: '0px',
  flexGrow: '1',
}))

const MainText = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.medium,
  fontWeight: '500',
  ...theme.textEllipsis[1],
}))

const SecondaryText = styled(Box)(({ theme }) => ({
  font: theme.font.roboto.paragraph.small,
  ...theme.textEllipsis[1],
}))

const autocompleteService = { current: null }

const NewAddress = ({
  onAdd,
  onAttachAddress,
  onSelectAddress,
  showLabel = true,
  showButtons,
  handleBack,
  containerStyles = {},
  subContainerStyles = {},
  mainFormStyles = {},
  customStyles={},
  editingRef,
  ...props
}) => {

  const { glossary } = useGlossary()

  const [inputValue, setInputValue] = useState('')
  const [address, setAddress] = useState(null)

  const handleAdd = useCallback((data, save) => {
    onAdd(data.id, save)
    setAddress(null)
    setInputValue('')
    onSelectAddress && onSelectAddress(false)
  }, [])

  return (
    <Container style={containerStyles}>
        <EditAddress
          address={address}
          setAddress={e => {
            setAddress(e)
            onSelectAddress && onSelectAddress(e !== null)
          }}
          onAttachAddress={onAttachAddress}
          onSend={handleAdd}
          mainFormStyles={mainFormStyles}
          customStyles={customStyles}
          showButtons={showButtons}
          handleBack={handleBack}
          ref={editingRef}
        />
    </Container>
  )
}

export default NewAddress
