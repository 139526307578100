import { useCallback, useState } from 'react'
import { useApolloClient } from '@apollo/client'

import { createLocationService } from '../services/createLocation'
import { updateLocationService } from '../services/updateLocation'
import { createAddressService } from '../services/createAddress'
import { updateAddressService } from '../services/updateAddress'
import { attachAddressService } from '../services/attachAddress'
import { setAsDefaultService } from '../services/setAsDefault'
import { getUserAddressesService } from '../services/getUserAddresses'

export default function useAddress() {

  const apolloClient = useApolloClient()
  const [loading, setLoading] = useState(false)

  const createLocation = useCallback((filters, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await createLocationService(apolloClient, { variables, options })
        resolve(response.createLocation)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updateLocation = useCallback((filters, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await updateLocationService(apolloClient, { variables, options })
        resolve(response.updateLocation)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const createAddress = useCallback((filters, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await createAddressService(apolloClient, { variables, options })
        resolve(response.createAddress)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const updateAddress = useCallback((filters, options) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const variables = filters || {}
        const response = await updateAddressService(apolloClient, { variables, options })
        resolve(response.updateAddress)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const attachAddress = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await attachAddressService(apolloClient, { id, options })
        resolve(response.addUserAddress)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const setAsDefault = useCallback((id, options = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await setAsDefaultService(apolloClient, { id, options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  const getUserAddresses = useCallback(options => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true)
        const response = await getUserAddressesService(apolloClient, { options })
        resolve(response)
        setLoading(false)
      } catch (error) {
        reject(error)
      }
    })
  }, [apolloClient])

  return {
    loading,
    createLocation,
    updateLocation,
    createAddress,
    updateAddress,
    attachAddress,
    setAsDefault,
    getUserAddresses,
  }
}
