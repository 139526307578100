import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useGlossary } from '@hooks/index'

const Root = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  alignItems: 'center',
}))

const ImageBox = styled(Box)(({ theme }) => ({
  width: '60%',
  height: '60%',
  aspectRatio: '1.4',
  backgroundImage: 'url(/images/EmptyState/NoMap.png)',
  backgroundSize:  'contain',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  cursor: 'pointer',
  marginBottom: theme.spacing(3),
}))

const MainLabel = styled(Box)(({ theme }) => ({
  fontWeight: '600',
  font: theme.font.roboto.subtitle.medium,
  color: theme.palette.gray[600],
}))
  
const Label = styled(Box)(({ theme }) => ({
  fontWeight: '400',
  font: theme.font.roboto.paragraph.medium,
  color: theme.palette.gray[500],
  textAlign: 'center',

}))
  

const NoMap = props => {

  const { glossary } = useGlossary()

  return <Root>
    <ImageBox/>
    <MainLabel>
      {glossary('MapUnavailable')}
    </MainLabel>
    <Label>
      {glossary('MapUnavailableDesc')}
    </Label>
  </Root>
}

export default NoMap
