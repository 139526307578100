import { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import useAddress from './useAddress'
import { useError } from '@features/App/hooks'
import baseConfig from '@baseConfig'

const useAddressFormLogic = ({ address, setAddress, onAttachAddress, onSend, phone }) => {
  const { createAddress, attachAddress, updateAddress, getUserAddresses } = useAddress()
  const { logError } = useError()

  const validatePostalCode = async code => {
      try {
        if(formik.values.country?.id!=='MX') return true
        const response = await window.fetch(
          `https://api.copomex.com/query/info_cp/${code}?token=${baseConfig.sepomex}`
        )
        if (!response.ok) return false
        const payload = await response.json()
        if (payload[0].response) return true
      } catch (error) {
        return false
      }
    }

  const validationSchema = yup.object({
    country: yup.object().required(),
    region: yup.object().required(),
    street: yup.string().required(),
    num: yup.string().required(),
    numInt: yup.string().nullable(),
    zipCode: yup.string().required().min(2).test(
          'zip-code-valid',
          d => `Postal code is invalid`,
          validatePostalCode
        ),
    district: yup.string().required(),
    locality: yup.string().required(),
    references: yup.string().nullable(),
    phone: yup.string().required(),
  })

  const formik = useFormik({
    initialValues: {
      country: address?.country ?? address?.region?.country ?? {},
      region: address?.region ?? {},
      street: address?.street,
      num: address?.num,
      numInt: address?.numInt,
      zipCode: address?.zipCode,
      district: address?.district,
      locality: address?.locality,
      references: address?.references,
      name: address?.name,
      phone: address?.phone ?? phone,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await handleAdd(values)
      } catch (error) {    
        logError(error)
      }
    },
  })

  const handleAdd = async (values) => {
    if (!values) return
    //if (!address.coordinates) return
    const region = values.region
    // const locationChanged = address.street !== values.street ||
    //   address.zipCode !== values.zipCode || address.district!==values.district
    let _address
    if (address?.id) { // Update
      _address = await updateAddress({
        input: {
          id: address.id,
          street: values.street,
          num: values.num,
          numInt: values.numInt,
          zipCode: values.zipCode,
          district: values.district,
          locality: values.locality,
          references: values.references,
          name: values.name,
          phone: values.phone,
          regionId: region.id,
        },
      })
      const _resp = await getUserAddresses({
        fetchPolicy: 'no-cache',
      })
      if (onAttachAddress) onAttachAddress(_resp.userAddresses)
      setAddress(_address)

    } else { // Create
      _address = await createAddress({
        input: {
          street: values.street,
          num: values.num,
          numInt: values.numInt,
          zipCode: values.zipCode,
          district: values.district,
          locality: values.locality,
          references: values.references,
          name: values.name,
          phone: values.phone,
          regionId: region.id,
          locationId: null,
        },
      })
      attachAddress(_address.id).then(attached => {
          if (onAttachAddress) onAttachAddress(attached)
          onSend(_address, true)
        })
    }
  }

  return {
    formik,
    handleAdd,
  }
}

export default useAddressFormLogic
