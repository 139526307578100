import { useState, useCallback, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

import baseConfig from '@baseConfig'
import useGlossary from '@hooks/useGlossary'
import useDebounce from '@hooks/useDebounce'
import useError from '@hooks/useError'
import useCountry from '@hooks/useCountry'
import useRegion from '@hooks/useRegion'

import CustomInput from '@components/CustomInput'
import CustomInputAutocomplete from '@components/CustomInputAutocomplete'
import GreenButton from '@components/GreenButton'
import GreenOutlinedButton from '@components/GreenOutlinedButton'
import SimplePhoneTextField from '@components/Forms/SimplePhoneTextField'
import useAddressFormLogic from './hooks/useAddressLogic'
import { useSession } from '@features/App/hooks'

const Container = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(2)}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

const MainForm = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateAreas: `
    'country country zipCode'
    'locality locality locality'
    'city city city'
    'street street street'
    'num num numInt'
    'district district district'
    'phone phone phone'
    'references references references'
  `,
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateAreas: `
    'country country country country zipCode zipCode'
    'locality locality locality city city city'
    'street street street num num numInt'
    'district district district phone phone phone'
    'references references references references references references'
  `,
  },
}))

const Row = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2),
  '& > button': {
    width: '50%',
  },
}))

export const EditAddress = forwardRef(({ address, setAddress, onAttachAddress, onSend, 
  customStyles, mainFormStyles, showButtons=false, handleBack, ...props }, ref) => {

  const { loading: loadingRegions, getRegions } = useRegion()
  const { loading: loadingCountries, getCountries } = useCountry()
  //const { createLocation, createAddress, attachAddress, updateAddress, getUserAddresses } = useAddress()
  const { glossary } = useGlossary()
  const { showAlert } = useError()
  const { session } = useSession()
  const { phone } = session.user
  const { formik } = useAddressFormLogic({ address, setAddress, onAttachAddress, onSend, phone})


  const [regions, setRegions] = useState([])
  const [countries, setCountries] = useState([])
  const [regionName, setRegionName] = useState(address?.region?.name ?? '')
  const [countryName, setCountryName] = useState(address?.country?.name ?? '')
  const [zipCodeValid, setZipCodeValid] = useState(false)
  //const [save, setSave] = useState(true)

  // for refreshing the zipCode and locality inputs
  const [zipCodeKey, setZipCodeKey] = useState(0)
  const [localityKey, setLocalityKey] = useState(0)

  //for autocomplete of state 
  const [open, setOpen] = useState(false)

  const debouncedRegionName = useDebounce(regionName, 500)
  const debouncedCountryName = useDebounce(countryName, 500)

  useImperativeHandle(ref, () => ({
    submit: formik.handleSubmit,
    resetForm: formik.resetForm,
    errors: formik.errors,
    isSubmitting: formik.isSubmitting,
    touchedFields: formik.touched,
  }))
 
  const fetchRegionsData =() => {
    const variables = {
      num: 50,
      asc: true,
      filter: {
        query: regionName,
      },
    }
    getRegions(variables).then(resp => {
      const _reg = resp.edges.map(edge => edge.node)
      setRegions(_reg)
      if (formik.values.region?.id) {
        const rSelected = _reg.filter(r => {
          return r.altId === formik.values.region.id || r.id===`${formik.values.country.id}-${formik.values.region.id}`
        })[0]
        if (rSelected) formik.setFieldValue('region', rSelected)
      }
    })
  }

  const fetchCountriesData = useCallback(() => {
    const variables = {
      num: 50,
      asc: true,
      filter: {
        query: countryName,
      },
    }
    getCountries(variables).then(resp => {
      setCountries(resp.edges.map(edge => edge.node))
    })
  }, [countries, countryName])


  const getPostalCodeInfo = async () => {
    try {
      // eslint-disable-next-line max-len
      const response = await window.fetch(
        `https://api.copomex.com/query/info_cp/${formik.values.zipCode}?token=${baseConfig.sepomex}`
      )
      if (!response.ok) return {}

      const payload = await response.json()
      if (payload[0].response) {
        formik.setFieldValue('locality', payload[0].response.municipio)
        setZipCodeValid(true)
        // if (!formik.values.region || !formik.values.region.id) {
        //   await getRegions({ num: 1, filter: { query: payload[0].response.estado }}).then(resp => {
        //     if (resp.edges.length > 0) {
        //       formik.setFieldValue('region', resp.edges[0].node)
        //     }
        //   })
        // }
      }
    } catch (error) {
      return {}
    }
  }

  const handleRegionChange = value => {
    formik.setFieldValue('region', value)
  }

  const handleCountryChange = value => {
    formik.setFieldValue('country', value)
    formik.setFieldValue('region', false)
    if(value?.id === 'MX') {
      formik.setFieldValue('zipCode', null)
      formik.setFieldValue('locality', null)
      setZipCodeKey(prev => prev + 1)
      setLocalityKey(prev => prev + 1)
    }
  }

  const handleCancel = useCallback(() => {
    setAddress(null)
    handleBack()
  }, [address])

  
  useEffect(() => {
    fetchRegionsData()
  }, [debouncedRegionName])

  useEffect(() => {
    fetchCountriesData()
  }, [debouncedCountryName])

  useEffect(() => {
    if (!formik.values.country?.id) return
    if (!formik.values.zipCode) return
    if (formik.values.zipCode !== '' && formik.values.country.id === 'MX' && formik.values.zipCode.length === 5) {
      // eslint-disable-next-line no-console
      getPostalCodeInfo().catch(error => console.log(error))
    }
  }, [formik.values.zipCode])

  useEffect(() => {
    if (!formik.isSubmitting && Object.keys(formik.errors).length > 0) {
      showAlert(glossary('InvalidData'), 'error')
      console.log(formik.errors)
    }
  }, [formik.isSubmitting])

  return (
    <Fragment style ={customStyles}>
      <Container>
        <MainForm style={mainFormStyles}>
          <CustomInput
            label={glossary('Street') + '*'}
            onChange={e => formik.setFieldValue('street', e.target.value)}
            name='street'
            value={formik.values.street}
            error={formik.touched.street && Boolean(formik.errors.street)}
            sx={{ gridArea: 'street' }}
          />
          <CustomInput
            label={glossary('NoExt') + '*'}
            onChange={e => formik.setFieldValue('num', e.target.value)}
            name='num'
            value={formik.values.num}
            error={formik.touched.num && Boolean(formik.errors.num)}
            sx={{ gridArea: 'num' }}
          />
          <CustomInput
            label={glossary('NoInt')}
            onChange={e => formik.setFieldValue('numInt', e.target.value)}
            name='numInt'
            value={formik.values.numInt}
            error={formik.touched.numInt && Boolean(formik.errors.numInt)}
            sx={{ gridArea: 'numInt' }}
          />
          <CustomInput
            label={glossary('PC') + '*'}
            key={zipCodeKey}
            onChange={e => formik.setFieldValue('zipCode', e.target.value)}
            name='zipCode'
            value={formik.values.zipCode}
            error={formik.touched.zipCode && (Boolean(formik.errors.zipCode) || !zipCodeValid)}
            sx={{ gridArea: 'zipCode' }}
          />
          <CustomInput
            label={glossary('Neighborhood') + '*'}
            onChange={e => formik.setFieldValue('district', e.target.value)}
            name='district'
            value={formik.values.district}
            error={formik.touched.district && Boolean(formik.errors.district)}
            sx={{ gridArea: 'district' }}
          />
          <CustomInput
            label={glossary('District') + '*'}
            key={localityKey}
            onChange={e => formik.setFieldValue('locality', e.target.value)}
            name='locality'
            value={formik.values.locality}
            error={formik.touched.locality && Boolean(formik.errors.locality)}
            disabled={formik.values.country?.id === 'MX'}
            sx={{ gridArea: 'locality' }}
          />
          <SimplePhoneTextField
            label={glossary('Phone') + '*'}
            onChange={newValue => formik.setFieldValue('phone', newValue)}
            name='phone'
            initialValue={formik.values.phone}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            sx={{ gridArea: 'phone' }}
          />
          <CustomInput
            label={glossary('References')}
            onChange={e => formik.setFieldValue('references', e.target.value)}
            name='references'
            value={formik.values.references}
            error={formik.touched.references && Boolean(formik.errors.references)}
            multiline
            minRows={3}
            maxRows={3}
            sx={{ gridArea: 'references' }}
          />
          <CustomInputAutocomplete
            label={glossary('Country') + '*'}
            value={formik.values.country}
            onChange={(event, value) => handleCountryChange(value)}
            loading={loadingCountries}
            options={countries}
            multiple={false}
            filterSelectedOptions={true}
            fullWidth
            noOptionsText={glossary('NoOptions')}
            getOptionLabel={option => (typeof option === 'string' ? option : option.name ?? '')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <CustomInput {...params} fullWidth value={countryName} onChange={e => setCountryName(e.target.value)} />
            )}
            sx={{ gridArea: 'country' }}
          />
          <CustomInputAutocomplete
            label={glossary('State')+'*'}
            value={formik.values.region}
            open={open}
            onOpen={() => {if (regionName.length > 0) setOpen(true)}}
            onChange={(event, value) => {handleRegionChange(value)
              setOpen(false)
            }}
            loading={loadingRegions}
            options={regions}
            multiple={false}
            filterSelectedOptions={true}
            fullWidth
            noOptionsText={glossary('NoOptions')}
            getOptionLabel={option => (typeof option === 'string' ? option : option.name ?? '')}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={params => (
              <CustomInput {...params} fullWidth value={regionName} onChange={e => setRegionName(e.target.value)} />
            )}
            sx={{ gridArea: 'city' }}
          />
        </MainForm>
        {showButtons && 
          <Row sx={{ gridArea: 'buttons' }}>
            <GreenOutlinedButton onClick={handleCancel}>{glossary('Cancel')}</GreenOutlinedButton>
            <GreenButton onClick={e => formik.handleSubmit()}>
              {glossary('Accept')}
            </GreenButton>
          </Row>
          }
      </Container>
    </Fragment>
  )
})

export default EditAddress
