import { getUserAddresses } from '@graphql/address.graphql'

export const getUserAddressesService = async (apolloClient, { options }) => {
  try {
    const resp = await apolloClient.query({
      query: getUserAddresses,
      ...options,
    })
    return resp.data
  } catch (error) {
    console.error(error)
    throw new Error(error)
  }
}
