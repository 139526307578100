import { useCallback, useEffect, useState } from 'react'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import EventEmitter from 'events'

import baseConfig from '@baseConfig'

class ReadyEmitter extends EventEmitter { }

const Root = styled('div', {
  shouldForwardProp: propName => {
    return propName !== 'hideControls' && propName !== 'hideReset' && propName !== 'hideLegend'
  },
})(({ hideControls, hideReset, hideLegend }) => {
  let props = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& *::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
  }
  if (hideControls)
    props = {
      ...props,
      '& #venue-map-zoom-controls': {
        display: 'none !important',
      },
    }
  if (hideReset)
    props = {
      ...props,
      '& .venue-map-reset': {
        display: 'none !important',
      },
    }
  if (hideLegend)
    props = {
      ...props,
      '& .venue-map-reset, .venue-map-reset-ctn, .maps-legend, .Sea-StaticMapMessage, .sea-child': {
        display: 'none !important',
      },
    }
  return props
})

const SuperMap = styled(props => <Box {...props} />)(({ theme, explorer }) => ({
  border: 0,
  width: '100%',
  overflowY: 'hidden',
  [theme.breakpoints.down('md')]: {
    overflowY: 'hidden',
    border: 0,
    width: '100%',
    height: explorer ? '100% !important' : '',
    '& .venue-map-reset': {
      color: '#212121',
    },
    '& .sea-map-inner': {
      height: '100% !important',
    },
  },
  [theme.breakpoints.up('md')]: {
    overflowY: 'hidden',
    border: 0,
    width: '100%',
    height: explorer ? '65vh' : '100% !important'
  },
  '& .venue-map-svg:nth-of-type(1) > g:nth-of-type(1) > path:nth-of-type(1)': {
    fill: 'transparent',
  },
}))

const Container = styled(props => <Box {...props} />)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}))

const Cover = styled(props => <Box {...props} />)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  transform: 'translateY(-100%)',
  zIndex: '2',
  position: 'absolute',
}))

/**
 *
 * @param tickets all the tickets from the event
 * @param setTickets
 * @param event
 * @param readOnly
 * @param hideControls
 * @param hideReset
 * @param hideLegend
 * @returns {JSX.Element}
 * @constructor
 */
const TicketeroMap = ({
  tickets,
  setTickets,
  event,
  readOnly = false,
  hideControls = false,
  hideReset = false,
  hideLegend = false,
  explorer = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [ticketsForTheMap, setTicketsForTheMap] = useState(tickets)
  const [loadingMap, setLoadingMap] = useState(true)
  const [selectedSectionsTickets, setSelectedSectionsTickets] = useState(null)
  const [isSeaticsMap, setIsSeaticsMap] = useState(true)

  const TicketeroDependencies = new ReadyEmitter()
  const links = ['../../../../css/map.css']
  const scripts = ['https://code.jquery.com/jquery-3.6.3.min.js']

  const getStaticImage = useCallback(async () => {
    try {
      const response = await fetch(`${baseConfig.ticketeroUrl}v2/search/event?eventId=${event.eventId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${baseConfig.ticketeroToken}`,
        },
      })
      if (!response.ok) {
        throw new Error('Error en la solicitud del mapa estatico del evento')
      }

      const data = await response.json()
      if (data.success) return [data.data.result.map, data.data.result.venue_name]
    } catch (e) {
      console.error(e)
    }
  }, [event])

  const loadScriptText = text => {
    const scriptTag = document.createElement('script')
    scriptTag.type = 'text/javascript'
    scriptTag.text = text
    document.body.appendChild(scriptTag)
  }

  window.updateTicketsSeatics = async ticketList => {
    let ticketsWithSection = ticketList.filter(t => t.type === 5)[0] ? ticketList.filter(t => t.type === 5)[0].tickets : []
    let otherOffers = ticketList.filter(t => t.type === 4)[0] ? ticketList.filter(t => t.type === 4)[0].tickets : []
    let t = ticketsWithSection
    if (ticketsForTheMap.length == ticketsWithSection.length + otherOffers.length) {
      t = t.concat(otherOffers)
    }
    setTickets(t)
  }

  window.updateTicketsTicketero = async ticketList => {
    if (ticketList[0] && ticketList[0].__typename == 'TicketForMap') setSelectedSectionsTickets(ticketList)
    else setSelectedSectionsTickets(ticketList[0].tickets)
  }

  window.ticketeroMap = async data => {
    if (typeof mapData !== 'undefined' && mapData !== null) {
      try {
        Ticketero.create({
          tickets: ticketsForTheMap, // array with the ticket objects
          mapData: mapData, // object with the map data
          updater: window.updateTicketsTicketero, // function triggered when an area is selected
          container: null, // reference to the container where the map is rendered
          enableTooltips: false, // enables tooltips
          containerName: '#superMap', // name of the container where the map is rendered
          enableLegends: false,
        })
      } catch (e) {
        console.error(e)
      }
    } else {
      let staticMap = await getStaticImage()
      let image = data[0]?.mapImage || staticMap[0]
      let mapName = data[0]?.mapName || staticMap[1]
      window.Seatics.config.hoverEnabled = true
      window.Seatics.config.noMapImageUrl = 'https://mapwidget3.seatics.com/Images/mapcomingsoon.png?v=3.0'
      window.Seatics.MapComponent.create({
        imgSrc: image,
        tickets: ticketsForTheMap,
        mapData: data[1],
        vfsUrl: 'https://vfs.seatics.com',
        container: window.$('#superMap'),
        presentationInterface: {
          updateTicketsList: window.updateTicketsSeatics,
        },
        mapWidth: 600,
        mapHeight: 600,
        mapName: mapName,
        enableSectionInfoPopups: true,
      })
      // this.myCreateListFunction(this.addons_res, "nofalse");
    }
  }

  const fetchMap = useCallback(async () => {
    try {
      const response = await window.fetch(
        `${baseConfig.ticketeroUrl}v3/search/map?` +
        new URLSearchParams({
          callback: 'window.ticketeroMap',
          eventId: event.eventId,
        }),
        {
          headers: {
            Authorization: `Bearer ${baseConfig.ticketeroToken}`,
            Accept: 'application/json',
          },
        }
      )
      const responseText = await response.text()
      loadScriptText(responseText)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('err', error)
    }
  }, [loadScriptText, event])

  let readyFiles = 0
  const loadedDependencies = []

  const increaseLoadedFiles = () => {
    if (readyFiles === scripts.length + links.length) return
    readyFiles += 1
    if (readyFiles === scripts.length + links.length) {
      TicketeroDependencies.emit('ready')
    }
  }

  const loadLinks = () => {
    links.forEach(link => {
      const linkTag = document.createElement('link')
      linkTag.rel = 'stylesheet'
      linkTag.type = 'text/css'
      linkTag.href = link
      linkTag.onload = increaseLoadedFiles
      loadedDependencies.push(linkTag)
      document.getElementsByTagName('head')[0].appendChild(linkTag)
    })
  }

  const loadScripts = () => {
    scripts.forEach(script => {
      const scriptTag = document.createElement('script')
      scriptTag.type = 'text/javascript'
      scriptTag.src = script
      scriptTag.onload = increaseLoadedFiles
      loadedDependencies.push(scriptTag)
      document.getElementsByTagName('html')[0].appendChild(scriptTag)
    })
  }

  useEffect(() => {
    if (!selectedSectionsTickets) return
    setTickets(selectedSectionsTickets)
  }, [selectedSectionsTickets])

  useEffect(() => {
    const onLoad = async () => {
      if (typeof $ !== 'undefined') {
        await fetchMap()
        setLoadingMap(false)
      } else {
        loadLinks()
        loadScripts()
      }
      TicketeroDependencies.on('ready', async () => {
        setTimeout(async () => {
          await fetchMap()
          setLoadingMap(false)
        }, 500)
      })
    }
    onLoad().catch(error => console.error(error))
    return function () {
      delete window.updateTickets
      delete window.ticketeroMap
      window.Seatics = null
      try {
        Ticketero.destroy()
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  return (
    <Root hideControls={hideControls} hideReset={hideReset} hideLegend={hideLegend || readOnly}>
      <Container style={{ height: '100%', width: '100%' }}>
        <SuperMap id='superMap' alt='logo' readOnly={readOnly} explorer={explorer} />
      </Container>
      {/* Beware, this messes up the whole thing: */}
      {/* readOnly && <Cover/> */}
      {loadingMap && (
        <Player autoplay loop src='/animations/Loading.json' style={{ width: '200px' }}>
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
      )}
    </Root>
  )
}

export default TicketeroMap
