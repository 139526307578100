import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material'

// import EditPenIcon from '@icons/EditPenIcon'
import useGlossary from '@hooks/useGlossary'
import CustomSwipeableViews from '@components/CustomSwipeableViews'
import NewAddress from './NewAddress'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  flexGrow: '1',
  [theme.breakpoints.up('md')]: {
    height: 'fit-content',
  },
}))

const AddressesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: `0 ${theme.spacing(1)}`,
  overflow: scroll,
  [theme.breakpoints.up('md')]: {
    maxHeight: '60vh',
  },
}))

const AddressCard = styled(props => <Button {...props}/>, {
  shouldForwardProp: propName => (
    propName !== 'active'
  ),
})(({ theme, active = false }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  border: `2px solid ${active ? theme.palette.primary.main : theme.palette.gray[30]}`,
  color: theme.palette.gray[700],
  textTransform: 'capitalize',
  textAlign: 'left',
  alignItems: 'flex-start',
  '& svg path': {
    fill: theme.palette.gray[500],
  },
}))

const AddressLabels = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  flexGrow: '1',
}))

const NewAddressBtn = styled(props => <Button {...props}/>)(({ theme }) => ({
  textTransform: 'capitalize',
  border: `1px solid ${theme.palette.gray[30]}`,
  padding: theme.spacing(2),
  margin: `0 ${theme.spacing(1)}`,
}))

const StyledCustomSwipeableViews = styled(props => <CustomSwipeableViews {...props}/>)(({ theme }) => ({
  flexGrow: '1',
  '& .react-swipeable-view-container': {
    height: '100% !important',
  },
}))


const Addresses = ({ addresses, setAddresses, onSelectAddress, activeId, onAdding,  editAddressRef, 
  setIsEditing, ...props }) => {

  const { glossary } = useGlossary()

  const [activeAddress, setActiveAddress] = useState(activeId)
  const [tabView, setTabView] = useState(1)

  const handleSelectAddress = (id, save) => {
    setActiveAddress(id)
    onSelectAddress(id, save)
    setIsEditing(false)
  }

  const handleAddAddress = (id, save) => {
    handleSelectAddress(id, save)
    setTabView(1)
  }

  const onAttachAddress = attachedAddresses => {
    setAddresses(attachedAddresses)
  }

  useEffect(() => {
    onAdding(tabView === 0)
    if(tabView === 0) setIsEditing(true)
  }, [tabView])

  return <Container>
    <NewAddressBtn
      color={'secondary'}
      onClick={e => setTabView(prev => prev === 0 ? 1 : 0)}
    >
      {
        tabView === 0 ? glossary('SeeSavedAddresses') : glossary('AddAddress')
      }
    </NewAddressBtn>
    <StyledCustomSwipeableViews
      index={tabView}
      onChangeIndex={i => {}}
      disabled={true}
    >
      { tabView === 0 &&
        <NewAddress onAdd={handleAddAddress} onAttachAddress={onAttachAddress} editingRef={editAddressRef}/>
      }
      { tabView === 1 &&
        <AddressesContainer>
          {
            addresses.map(_address => <AddressCard
              onClick={e => handleSelectAddress(_address.id, false)}
              key={_address.id}
              active={activeAddress === _address.id}
            >
              <AddressLabels>
                {
                  _address.name && <Box>
                    { _address.name }
                  </Box>
                }
                <Box>
                  {_address.street} {_address.num}
                  {_address.numInt?.length > 1 ? ' ' + _address.numInt + ',' : ',' }
                </Box>
                <Box>
                  {_address.zipCode}, {_address.district}, {_address.locality}
                </Box>
              </AddressLabels>
              {/* <EditPenIcon/> */}
            </AddressCard>)
          }
        </AddressesContainer>
      }
    </StyledCustomSwipeableViews>
  </Container>
}

export default Addresses
