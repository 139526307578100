import { useLayoutEffect } from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import chroma from 'chroma-js'
import dynamic from 'next/dynamic'

import { MAP_COLORS } from '@constants/mapColors'
import NoMap from './NoMap'

const MapContainer = styled(props => <Box {...props} />)(({ theme, mono }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& svg': {
    height: '80%',
  },
  fill: '#E2E2E2',
  // '& g[id].active': {
  //   fill: 'red',
  // },
  '& .white, .text': {
    fill: '#FEFEFE',
  },
  '& .stroke-white': {
    stroke: '#FEFEFE',
  },
  '& .grass': {
    fill: '#7EB168',
  },
  '& .light-green': {
    fill: '#BFD8B4',
  },
  '& .dark': {
    fill: '#4D4D4D',
  },
  '& .invisible': {
    opacity: '0',
  },
  '& .brown': {
    fill: '#8E745B',
  },
}))

const NoMapAvailable = () => {
  return <NoMap />
}

const VenueViewer = ({ forum, activeSection = '' }) => {
  const mapName = forum?.stadibox_map?.mapFile ?? ''

  const Map = dynamic(() =>
    import(`./../Maps/${mapName}`)
      .then(module => module.default)
      .catch(() => NoMapAvailable)
  )

  useLayoutEffect(() => {
    if (!Map) return
    setTimeout(() => {
      let num = 0
      const mapContainerElem = document.querySelector('#venue-viewer-map')
      if (!mapContainerElem) return
      const elementsG = mapContainerElem.querySelectorAll('g[id]')
      elementsG.forEach(elementG => {
        const _num = parseInt(elementG.getAttribute('num'))
        if (!isNaN(_num) && _num > num) num = _num
      })
      const pathClearSections = document.querySelectorAll('g[id] path')
      const polygonClearSections = document.querySelectorAll('g[id] polygon')
      const rectClearSections = document.querySelectorAll('g[id] rect')

      const clearSections = [...pathClearSections, ...rectClearSections, ...polygonClearSections]

      clearSections.forEach(section => section.setAttribute('fill', '#E2E2E2'))

      const colors = chroma.scale(MAP_COLORS).mode('lch').colors(num)
      const groupElement = mapContainerElem.querySelector(`g[id="${activeSection}"]`)
      if (groupElement) groupElement.setAttribute('class', 'active')
      const pathElements = mapContainerElem.querySelectorAll(`g[id="${activeSection}"] path`)
      const polygonElements = mapContainerElem.querySelectorAll(`g[id="${activeSection}"] polygon`)
      const rectElements = mapContainerElem.querySelectorAll(`g[id="${activeSection}"] rect`)
      const elements = [...pathElements, ...rectElements, ...polygonElements]

      elements.forEach(element => {
        element.setAttribute('fill', colors[groupElement.getAttribute('num') ?? 0])
      })
    }, 1000)
  }, [Map])

  return <MapContainer id={'venue-viewer-map'}>{mapName !== '' && <Map />}</MapContainer>
}

export default VenueViewer
